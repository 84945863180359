export const envBasedSentryTracingSampleRate = (env: string | undefined): number => {
    switch (env) {
        case 'dev':
        case 'stage':
            return 0.01
        case 'prod':
            return 0.1
        default:
            return 0
    }
}
