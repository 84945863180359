export enum Role {
    BASIC = 'sg_lmt_basic', // Only for getting general access to the product through EV world
    DEVELOPER = 'sg_lmt_developer',
    ADMIN = 'sg_lmt_admin',

    ACCOUNTING = 'sg_lmt_accounting',
    CONTROLLING = 'sg_lmt_controlling',
    EDITOR = 'sg_lmt_editor',
    RELATIONSHIP_MANAGER = 'sg_lmt_rsm',
    GG = 'sg_lmt_gg',
    ACADEMY = 'sg_lmt_academy',
}
