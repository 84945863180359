export const DataTestIds = {
    globals: {
        header: {
            username: 'header.username',
        },
        pagination: 'pagination',
        search: 'search',
        selectValue: (dataTestId: string, value: string) => `${dataTestId}_${value}`,
    },
    licenceContracts: {
        filters: {
            businessUnit: 'businessUnit',
            contractType: 'contractType',
            licenceContractStatus: 'licenceContractStatus',
        },
    },
    legalEntities: {
        filters: {
            selectableAsLicensor: 'selectableAsLicensor',
            companyOwnedOperation: 'companyOwnedOperation',
        },
    },
}
